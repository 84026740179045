<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="createForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          :form="$refs.createForm"
          :disabled="invalid || checkUsernameLoading"
          @submit="submit"
        >
          <n-input
            v-model="data"
            :fields="fields"
            :init-value="initData"
          >
            <template #isVendor="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <b-form-checkbox
                    v-if="item.field.type === 'checkbox'"
                    :id="item.field.key"
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :value="true"
                    :unchecked-value="false"
                    :disabled="item.field.disabled"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    {{ $t(item.field.label) }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <n-button-loading
                v-if="data.isVendor"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="primary"
                class="mb-1"
                :loading="loading"
                @submit="generateCredential"
              >
                {{ $t("button.generateCredential") }}
              </n-button-loading>
            </template>
            <template #vendorKey="item">
              <b-form-group
                v-if="data.isVendor"
                :label-for="item.field.key"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <b-input-group>
                    <b-form-input
                      v-if="item.field.type === 'text'"
                      v-model="data[item.field.key]"
                      :placeholder="$t(item.field.label)"
                      :name="item.field.key"
                      :disabled="item.field.disabled"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      autocomplete="off"
                    />
                    <template #append>
                      <b-button
                        variant="primary"
                        :disabled="!data[item.field.key]"
                        @click="copy(data[item.field.key])"
                      >
                        <feather-icon icon="CopyIcon" />
                      </b-button>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span v-else />
            </template>
            <template #vendorSecret="item">
              <b-form-group
                v-if="data.isVendor"
                :label-for="item.field.key"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <b-input-group>
                    <b-form-input
                      v-if="item.field.type === 'text'"
                      v-model="data[item.field.key]"
                      :placeholder="$t(item.field.label)"
                      :name="item.field.key"
                      :disabled="item.field.disabled"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      autocomplete="off"
                    />
                    <template #append>
                      <b-button
                        variant="primary"
                        :disabled="!data[item.field.key]"
                        @click="copy(data[item.field.key])"
                      >
                        <feather-icon icon="CopyIcon" />
                      </b-button>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span v-else />
            </template>
            <template #vendorBetWebhook="item">
              <b-form-group
                v-if="data.isVendor"
                :label-for="item.field.key"
              >
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <b-form-input
                    v-if="item.field.type === 'text'"
                    v-model="data[item.field.key]"
                    :placeholder="$t(item.field.label)"
                    :name="item.field.key"
                    :disabled="item.field.disabled"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <span v-else />
            </template>
            <template #loginTimeout="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="timeout"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #accrueDepositPeriod="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="dropCommissionPeriod"
                    clearable
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #parentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :init-options="data[item.field.key]"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :readonly="getSuggestionUsernameLoading"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    @optionChange="changeOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #username="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <input
                    v-model="data[item.field.key]"
                    type="hidden"
                    :name="item.field.key"
                  >
                  <b-row>
                    <n-single-select
                      v-model="username.first"
                      :options="alphabet"
                      :clearable="false"
                      :loading="checkUsernameLoading"
                      :readonly="getSuggestionUsernameLoading"
                      class="col-6"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    />
                    <n-single-select
                      v-model="username.second"
                      :options="alphabet"
                      :clearable="true"
                      :loading="checkUsernameLoading"
                      :readonly="getSuggestionUsernameLoading"
                      class="col-6"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    />
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('create', 'agent')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid || checkUsernameLoading"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BInputGroup,
  BLink,
  BRow,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import NInput from '@/components/NInput.vue'
import Alphabet from '@/data/numberAlphabet'
import Timeout from '@/data/timeout'
import DropCommissionPeriod from '@/data/dropCommissionPeriod'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormInput from './formInput'

const AgentRepository = Repository.get('agent')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BInputGroup,
    NButtonLoading,
    BButton,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    NAsyncSingleSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        channelIds: [],
        lastName: null,
        phone: null,
        username: null,
        password: null,
        commission: 0,
        share: 0,
        minBetTime: 0,
        maxBetTime: 0,
        maxBetGame: 0,
        maxDrawBetGame: 0,
        maxWinDay: 0,
        maxBetAmountParley: 0,
        minBetTimeBacar: 0,
        maxBetTimeBacar: 0,
        maxBetGameBacar: 0,
        maxBetDrawPerGameBacar: 0,
        maxWinDayBacar: 0,
        ccy: null,
        isEnable: true,
        isReadonly: false,
        isCashMarket: false,
        isVendor: false,
        vendorKey: null,
        vendorSecret: null,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      getSuggestionUsernameLoading: false,
      username: {
        parent: null,
        first: null,
        second: null,
      },
    }
  },
  computed: {
    typeId() {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData?.typeId
    },
  },
  watch: {
    'username.first': function (value) {
      this.concatUsername()
    },
    'username.second': function (value) {
      this.concatUsername()
    },
    'username.parent': function (value) {
      this.concatUsername()
    },
    'data.parentId': function (value) {
      this.getSuggestUsername(value)
    },
    'data.username': function (value) {
      if (value) {
        this.checkUsername(value)
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.show()
    }
    if (this.typeId != 1) {
      const index = this.fields.findIndex(
        item => item.key === 'isCashMarket',
      )
      if (index != -1) {
        this.fields.splice(index, 1)
      }
    }
  },
  methods: {
    changeOption(value) {
      this.username.parent = value?.username
    },
    show() {
      AgentRepository.show(this.$route.query.id).then(response => {
        delete response.data.data.firstName
        delete response.data.data.lastName
        delete response.data.data.phone
        delete response.data.data.email
        delete response.data.data.username
        delete response.data.data.profilePicture

        this.initData = {
          ...response.data.data,
        }
      })
    },
    submit() {
      if (!this.usernameValid) {
        return
      }
      this.$refs.createForm.validate().then(success => {
        if (success) {
          this.loading = true
          AgentRepository.create(this.data)
            .then(response => {
              this.$router.push({
                name: 'view-agent',
                params: { id: response.data.data.id },
              })
            })
            .catch(error => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.errors)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    getSuggestUsername(parentId) {
      this.checkUsernameLoading = true
      this.getSuggestionUsernameLoading = true
      AgentRepository.getSuggestUsername(parentId)
        .then(response => {
          const data = response.data?.data
          let { username } = data
          username = username.replace(data.parentUsername, '')
          this.username.first = username.charAt(0)
          this.username.second = username.charAt(1)
          this.usernameValid = true
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false
          this.getSuggestionUsernameLoading = false
        })
    },
    checkUsername(username) {
      if (this.getSuggestionUsernameLoading) {
        return
      }

      this.checkUsernameLoading = true
      AgentRepository.checkUsername(username)
        .then(response => {
          if (response.data?.data.used) {
            this.$refs.createForm.setErrors({
              username: this.$t('validation.unique', {
                field: this.$t('field.username'),
              }),
            })
            this.usernameValid = false
          } else {
            this.usernameValid = true
          }
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false
        })
    },
    concatUsername() {
      if (this.username.first && this.username.parent) {
        this.data.username = `${this.username.parent}${this.username.first}${
          this.username.second ? this.username.second : ''
        }`
      }
    },
    back() {
      this.$router.back()
    },
    generateCredential() {
      this.loading = true
      AgentRepository.generateCredential()
        .then(response => {
          const { data } = response.data
          this.data.vendorKey = data.key
          this.data.vendorSecret = data.secret
        })
        .catch()
        .then(() => {
          this.loading = false
        })
    },
    copy(value) {
      const clipboardData = event.clipboardData
        || window.clipboardData
        || event.originalEvent?.clipboardData
        || navigator.clipboard

      clipboardData.writeText(value)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('alert.copied'),
          icon: 'BellIcon',
          text: this.$t('alert.copiedToClipboard'),
          variant: 'info',
        },
      })
    },
  },
  setup() {
    const fields = FormInput.map(value => {
      if (value.key === 'password' || value.key === 'retypePassword') {
        value.rules = value.rules.replace('required|', '')
        value.rules = value.rules.replace('required', '')
        value.rules = value.rules.replace('', 'required|')
      } else if (value.key === 'givenCredit') {
        value.disabled = false
      }

      return value
    })

    const alphabet = [...Alphabet]
    const timeout = [...Timeout]
    const dropCommissionPeriod = [...DropCommissionPeriod]
    return {
      fields, alphabet, timeout, dropCommissionPeriod,
    }
  },
}
</script>

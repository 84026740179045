import i18n from '@/libs/i18n'

export default [
  {
    id: 'daily',
    name: i18n.tc('field.daily'),
  },
  {
    id: 'weekly',
    name: i18n.tc('field.weekly'),
  },
  {
    id: 'monthly',
    name: i18n.tc('field.monthly'),
  },
  {
    id: 'everytime',
    name: i18n.tc('field.everytime'),
  },
]
